export const ampConfig = {
    Auth: {
        identityPoolId: 'us-east-1:4d8eb501-bf3b-4778-b51d-013d07b418ae',
        region: 'us-east-1',
        identityPoolRegion: 'us-east-1',
        userPoolId: 'us-east-1_6LhOVk9IA',
        userPoolWebClientId: '2ohpap9nr71pc95kutacn99grc',
        mandatorySignIn: true,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        cookieStorage: {
            domain: process.env.REACT_APP_HIVE_ENV === 'dev' ? 'localhost' : 'darksidesecurity.io',
            path: '/',
            expires: 30,
            secure: false,
        },
    },
};