import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { Auth } from "aws-amplify";
import { apiBasePath } from '../config';
import { useNavigate } from 'react-router-dom';

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        axios.defaults.headers.common.Authorization = accessToken
    } else {
        document.cookie = ""
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
    update: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const navigate = useNavigate();
    const login = async ({email, password}) => {
        await Auth.signIn(email, password).then(async(res) =>{
            const ses = await Auth.currentSession();
            const token = ses.getIdToken().getJwtToken();
            setSession(token);
            dispatch({
                type: 'LOGIN',
                payload: {
                    user: res.attributes,
                },
            })
        }).catch((err)=>{
            throw new Error(err.toString())
        });
        
    }
    const update = async(dto) => {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            family_name: dto.family_name,
            given_name: dto.given_name,
            email: dto.email,
            preferred_username: dto.preferred_username
        }).then((res) => {
            let u = {...user};
            u.family_name = dto.family_name;
            u.given_name = dto.given_name;
            u.email = dto.email;
            u.preferred_username = dto.preferred_username;
            dispatch({
                type: 'LOGIN',
                payload: {
                    user: u,
                },
            })
        });
    }
    const register = async (dto) => {
        try{
            let tempState = {...dto};
            await Auth.signUp({
                username: tempState.username,
                password: tempState.password,
                attributes: {
                    preferred_username: tempState.userName,
                    email: tempState.email,
                    name: `${tempState.firstname} ${tempState.lastname}`,
                    given_name: tempState.firstname,
                    family_name: tempState.lastname,
                    'custom:License': tempState.license_key
                }
            }).then(res =>{
                Auth.signIn(tempState.userName, tempState.password).then((r2) => {
                    const { accessToken, user } = r2.data

                    setSession(accessToken)

                    dispatch({
                        type: 'REGISTER',
                        payload: {
                            user,
                        },
                    })
                }).catch((err) => {
                    console.log(err);
                    return({success: false, msg: err})
                });
            }).catch((err) => {
                console.log(err);
                return({success: false, msg: err})
            });
            
        }catch(ex){
            console.log(ex);
        }
    }

    const logout = () => {
        setSession(null)
        Auth.signOut();
        dispatch({ type: 'LOGOUT' })
        navigate("/");
    }

    useEffect(() => {
        (async () => {
            try {
                const userInfo = await Auth.currentUserInfo();
                //console.log(`USerInfo: ${JSON.stringify(userInfo)}`);
                if(userInfo){
                    
                    const ses = await Auth.currentSession();
                    const accessToken = ses.getIdToken().getJwtToken();
                    if (accessToken && isValidToken(accessToken)) {
                    // console.log('Passed is ValidToken')
                        setSession(accessToken)
                        // let user = await axios.get(apiBasePath +"/users/me").then(dbuser => {
                        //     let u = {...dbuser.data.u};            
                        //     u.name = u.alias;
                        //     console.log(u);
                        let user = userInfo.attributes;
                            dispatch({
                                type: 'INIT',
                                payload: {
                                    isAuthenticated: true,
                                    user: user,
                                },
                            })
                        //});                        
                    } else {
                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: false,
                                user: null,
                            },
                        })
                    }
                }
                else{
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                //console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return (      
        <div id="">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>)
    }
    else{
        return (
            <AuthContext.Provider
                value={{
                    ...state,
                    method: 'JWT',
                    login,
                    logout,
                    register,
                    update,
                }}
            >
                {children}
            </AuthContext.Provider>
        )
    }    
}

export default AuthContext
