import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";


const app = (

    <App />

);
const container = document.getElementById("root")
const root = createRoot(container)
root.render(app);
serviceWorker.unregister();
