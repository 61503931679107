import React from "react";
import DssLayout from "./DssLayout/";
// Root Include
const DssHome = React.lazy(()=> import("./root"));
const DssAbout = React.lazy(()=> import("./about/about"));
const DssAppsec = React.lazy(()=> import("./services/appsec"));
const DssPentest = React.lazy(()=> import("./services/pentest"));
const DssHive = React.lazy(()=> import("./products/hive/index"));
const DssAphids = React.lazy(()=> import("./products/aphids/index"));
const DssHiveRegister = React.lazy(() => import("./products/hive/Register"));
const SignUp = React.lazy(() => import("./auth/SignUp"));
const Login = React.lazy(() => import("./auth/login"));
const Reset = React.lazy(() => import("./auth/Reset"));
const Account = React.lazy(() => import("./auth/Account"));
const DssToS = React.lazy(() => import("./legal/DSSTermsOfService"));
const DssPrivacy = React.lazy(() => import("./legal/DSSPrivacyPolicy"));
const DssAcceptableUse = React.lazy(() => import("./legal/DSSAcceptableUse"));
const DssContact = React.lazy(() =>
  import("./contact/ContactDetail")
);
// //Special
const PageError = React.lazy(() => import("./default/PageError"));

const routes = [

  { path: "/", component: <DssLayout hasDarkTopBar={false} useDarkNav={false}>
  <DssHome />
</DssLayout>, exact: true },
  { path: "/contact", component: <DssContact />, isWithoutLayout: true, isTopbarDark: true, exact: true, useDarkNav: true },
  { path: "/about", component: 
  <DssLayout hasDarkTopBar={true} useDarkNav={true}><DssAbout /></DssLayout>,},
  // { 
  //   path: "/services/pentest", 
  //   component: 
  //     <DssLayout hasDarkTopBar={false} useDarkNav={false}>
  //       <DssPentest />
  //     </DssLayout>, 
  //   isTopbarDark: false,
  //   useDarkNav: true
  // },
  // { path: "/services/appsec", component: <DssLayout hasDarkTopBar={false} useDarkNav={false}><DssAppsec /></DssLayout>, isTopbarDark: false, useDarkNav: true},
  { path: "/products/hive", component: <DssLayout hasDarkTopBar={true} useDarkNav={true}><DssHive /></DssLayout>, isTopbarDark: true, useDarkNav: true},
  { path: "/products/hive/register", component: <DssLayout hasDarkTopBar={true} useDarkNav={true}><DssHiveRegister /></DssLayout>, isTopbarDark: true, useDarkNav: true},
  { path: "/products/aphids", component: <DssLayout hasDarkTopBar={true} useDarkNav={true}><DssAphids /></DssLayout>, isTopbarDark: true, useDarkNav: true},
  { path: "/signup", component: <DssLayout hasDarkTopBar={true} useDarkNav={true}><DssHiveRegister /></DssLayout>, isTopbarDark: true, useDarkNav: true},
  { path: "/login", component: <Login />},
  { path: "/reset", component: <Reset />},
  { path: "/account/:nav", component: <DssLayout hasDarkTopBar={true} useDarkNav={true}><Account /></DssLayout>,isTopbarDark: true, useDarkNav: true},
  { path: "/account", component: <DssLayout hasDarkTopBar={true} useDarkNav={true}><Account /></DssLayout>,isTopbarDark: true, useDarkNav: true},
  { path: "/tos", component: <DssLayout hasDarkTopBar={false} useDarkNav={false}><DssToS /></DssLayout>, isTopbarDark: false, useDarkNav: true},
  { path: "/aup", component: <DssLayout hasDarkTopBar={false} useDarkNav={false}><DssAcceptableUse /></DssLayout>, isTopbarDark: false, useDarkNav: true},
  { path: "/privacy", component: <DssLayout hasDarkTopBar={false} useDarkNav={false}><DssPrivacy /></DssLayout>, isTopbarDark: false, useDarkNav: true},
  { path: "*", component: <PageError />, isWithoutLayout: true, exact: false },
];
// export const auth_routes = [
//   { path: "/account", component: <Account />},
// ];
export default routes;
