import React, { Component, Suspense } from "react";
import DssLayout from "./DssLayout/";
import {
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";

// Import Css
//import "./assets/css/materialdesignicons.min.css";
import "./Apps.scss";
import "./assets/css/colors/default.css";
// Include Routes
import routes from "./routes";
import { auth_routes } from "./routes";
import { Amplify } from 'aws-amplify';
import { ampConfig } from "./auth/AuthConstants";
import { AuthProvider } from "./auth/AuthContext";
import useAuth from "./hooks/useAuth";

Amplify.configure(ampConfig);



function withDssLayout(WrappedComponent, hasDarkTopBar, useDarkNav) {
  return class extends React.Component {
    render() {
      return (
        <DssLayout hasDarkTopBar={hasDarkTopBar} useDarkNav={useDarkNav}>
          <WrappedComponent></WrappedComponent>
        </DssLayout>
      );
    }
  };
}

const App =()=> {

    const Loader = () => {
      return (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <div className="double-bounce1"></div>
              <div className="double-bounce2"></div>
            </div>
          </div>
        </div>
      );
    };
    const {user, isAuthenticated} = useAuth();
    
    return (
      <React.Fragment>
          <Suspense fallback={Loader()}>
            <Router>
              <AuthProvider>
                <Routes>
                  {routes.map((route, idx) =>
                      (
                      <Route
                        path={route.path}
                        exact={route.exact}
                        element={route.component}
                        key={idx}
                      />
                    ) 
                    
                    )}
                  
                </Routes>
              </AuthProvider>
            </Router>
            
          </Suspense>
      </React.Fragment>
    );
}

export default App;
